<template>
	<div>
		<div v-if="data">
			<div v-if="data.sites && data.sites.length > 0">
				<h3>Used on sites:</h3>
				<div class="row" v-for="(site, sindex) in data.sites" v-bind:key="sindex">
					<div class="col-md-12">
						<router-link :to="{ name: 'site', params: { id: site.SiteId } }"
							>{{ site.Name }}
						</router-link>
						<router-link :to="{ name: 'admineditsite', params: { id: site.SiteId } }">
							<div class="btn btn-icon btn-clean btn-sm" v-b-tooltip.hover.bottom="'Edit Site'">
								<span class="svg-icon svg-icon-sm">
									<inline-svg src="/media/svg/icons/Design/Edit.svg" />
								</span>
							</div>
						</router-link>
					</div>
				</div>
			</div>
			<div v-else>
				<h3>Device not assigned to any sites</h3>
			</div>
			<DateRangeSelector @update="dateRangeChanged" :loaded="loaded" />
			<div class="row widget-row" v-for="(widget, rindex) in widgets" v-bind:key="rindex">
				<div :class="['col-md-12']" style="padding-left: 15px; padding-right: 15px">
					<component v-if="widget" :is="widget.name" v-bind="widget.props" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

const component_names = [
	{ widgetId: 1, name: 'IdirectModemStatus' },
	{ widgetId: 2, name: 'IdirectGraphs' },
	{ widgetId: 3, name: 'SiteMap' },
	{ widgetId: 4, name: 'IdirectDetails' },
	{ widgetId: 5, name: 'Idirect24HourUsage' },
	{ widgetId: 6, name: 'IdirectIPInfo' },
	{ widgetId: 7, name: 'SiteVoip' },
	{ widgetId: 8, name: 'IdirectBeamSwitches' },
	// 9-18 Deprecated
	{ widgetId: 19, name: 'Exinda' },
	{ widgetId: 20, name: 'IdirectUsageByProtocol' },
	{ widgetId: 21, name: 'SolarTransit' },
	{ widgetId: 22, name: 'SolarwindsGraphs' },
	{ widgetId: 23, name: 'IdirectHeatMap' },
	{ widgetId: 24, name: 'SolarwindsIPSLA' },
	{ widgetId: 25, name: 'Solarwinds4G' },
	{ widgetId: 26, name: 'SolarwindsComtechEbN0' },
	{ widgetId: 27, name: 'SolarwindsComtechPower' },
	{ widgetId: 28, name: 'SolarwindsComtechTemp' },
	{ widgetId: 29, name: 'SolarwindsComtechInterfaces' },
	{ widgetId: 30, name: 'SolarwindsIPSLAAverages' },
	// 31 Crew Live WiFi - discontinued
	// 32 IPS Security - discontinued?
	{ widgetId: 33, name: 'SolarwindsDailyGraph' },
	{ widgetId: 34, name: 'SolarTransitComtech' },
	{ widgetId: 35, name: 'SolarwindsNetFlow' },
	{ widgetId: 36, name: 'SiteComtechMap' },
	{ widgetId: 37, name: 'SolarwindsAvailability' },
	//	38 SolarwindsInterfaceErrors - wasn't ever used, so discontinued
	//	39-45 O3b - discontinued until SES portal availabile
	//	{ widgetId: 46, name: 'CiscoUmbrella' },
	//  { widgetId: 47, name: 'PeplinkBandwidth' },
	//  { widgetId: 48, name: 'PeplinkSignal' },
	{ widgetId: 998, name: 'SiteQuota' },
];

export default {
	name: 'AdminDevice',
	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			data: null,
			idirect: null,
			widgetslist: [],
			widgets: {},
		};
	},
	components: {
		DateRangeSelector: () => import('@/view/content/widgets/components/DateRangeSelector.vue'),
		Widget: () => import('@/view/content/widgets/site/Widget.vue'),
		IdirectModemStatus: () => import('@/view/content/widgets/site/IdirectModemStatus.vue'),
		IdirectGraphs: () => import('@/view/content/widgets/site/IdirectGraphs.vue'),
		SiteMap: () => import('@/view/content/widgets/site/SiteMap.vue'),
		IdirectDetails: () => import('@/view/content/widgets/site/IdirectDetails.vue'),
		Idirect24HourUsage: () => import('@/view/content/widgets/site/Idirect24HourUsage.vue'),
		IdirectIPInfo: () => import('@/view/content/widgets/site/IdirectIPInfo.vue'),
		SiteVoip: () => import('@/view/content/widgets/site/SiteVoip.vue'),
		IdirectBeamSwitches: () => import('@/view/content/widgets/site/IdirectBeamSwitches.vue'),
		Exinda: () => import('@/view/content/widgets/site/Exinda.vue'),
		IdirectUsageByProtocol: () => import('@/view/content/widgets/site/IdirectUsageByProtocol.vue'),
		SolarTransit: () => import('@/view/content/widgets/site/SolarTransit.vue'),
		IdirectHeatMap: () => import('@/view/content/widgets/site/IdirectHeatMap.vue'),
		SolarwindsGraphs: () => import('@/view/content/widgets/site/SolarwindsGraphs.vue'),
		SolarwindsIPSLA: () => import('@/view/content/widgets/site/SolarwindsIPSLA.vue'),
		SolarwindsIPSLAAverages: () =>
			import('@/view/content/widgets/site/SolarwindsIPSLAAverages.vue'),
		Solarwinds4G: () => import('@/view/content/widgets/site/Solarwinds4G.vue'),
		SolarwindsComtechEbN0: () => import('@/view/content/widgets/site/SolarwindsComtechEbN0.vue'),
		SolarwindsComtechPower: () => import('@/view/content/widgets/site/SolarwindsComtechPower.vue'),
		SolarwindsComtechTemp: () => import('@/view/content/widgets/site/SolarwindsComtechTemp.vue'),
		SolarwindsComtechInterfaces: () =>
			import('@/view/content/widgets/site/SolarwindsComtechInterfaces.vue'),
		SolarwindsDailyGraph: () => import('@/view/content/widgets/site/SolarwindsDailyGraph.vue'),
		SolarTransitComtech: () => import('@/view/content/widgets/site/SolarTransitComtech.vue'),
		SolarwindsNetFlow: () => import('@/view/content/widgets/site/SolarwindsNetFlow.vue'),
		SiteComtechMap: () => import('@/view/content/widgets/site/SiteComtechMap.vue'),
		SolarwindsAvailability: () => import('@/view/content/widgets/site/SolarwindsAvailability.vue'),
		SiteQuota: () => import('@/view/content/widgets/site/SiteQuota.vue'),
	},
	methods: {
		setComponentProps() {
			this.updated = Date.now();
			var components = {};

			if (this.idirect && this.idirect.stats?.data?.graphs?.maxsattraffic) {
				delete this.idirect.stats?.data?.graphs?.maxsattraffic;
				delete this.idirect.stats?.data?.graphs?.maxtraffic;
			}
			for (let widget of this.widgetslist) {
				let widgetId = parseInt(widget.WidgetId);

				// ignore discontinued widgets
				// prettier-ignore
				if([3,9,10,11,12,13,14,15,16,17,18,31,32,38,39,40,41,42,43,44,45].includes(widgetId)){					
					continue;
					}

				let compo = component_names.find((c) => c.widgetId === widgetId);

				if (!compo) {
					components[widget.WidgetArrId] = {
						widgetId: 0,
						name: 'Widget',
						props: { props: widget },
					};
				} else {
					var comp = {
						widgetId: compo.widgetId,
						name: compo.name,
					};

					switch (comp.name) {
						// 1
						case 'IdirectModemStatus':
							comp.props = {
								name: this.idirect.Name,
								deviceId: parseInt(widget.DeviceId),
								network: this.idirect.stats?.data?.currentnet?.NetworkName,
								promise: this.getPromise('modemStatus'),
							};
							break;
						// 2
						case 'IdirectGraphs':
							comp.props = {
								name: this.idirect.Name,
								graphs: this.idirect.stats?.data?.graphs,
							};
							break;

						//4
						case 'IdirectDetails':
							comp.props = {
								device: this.idirect,
								widgetProps: {},
								updated: this.updated,
							};
							break;
						//5
						case 'Idirect24HourUsage':
							comp.props = {
								name: this.idirect.Name,
								data: this.idirect.stats.data,
							};
							break;
						// 6
						case 'IdirectIPInfo':
							comp.props = {
								name: this.idirect.Name,
								ipinfo: this.idirect.stats.data.ipinfo,
								currentnet:
									this.idirect.stats.data.currentnet?.NetworkId &&
									this.idirect.stats.data.networks.length > 1
										? this.idirect.stats.data.currentnet?.NetworkId
										: null,
								updated: this.updated,
							};
							break;
						// 7
						case 'SiteVoip':
							comp.props = {
								name: this.data.Name,
								id: this.id,
								tsip: this.data.tsip,
							};
							break;
						// 8
						case 'IdirectBeamSwitches':
							comp.props = {
								name: this.idirect.Name,
								deviceId: widget.DeviceId,
								networks: this.idirect.stats.data.networks,
								updated: this.updated,
							};
							break;
						// 19
						case 'Exinda':
							comp.props = {
								id: this.data.Source,
								properties: widget?.properties,
							};
							break;
						// 20
						case 'IdirectUsageByProtocol':
							comp.props = {
								name: this.idirect.Name,
								DID: this.idirect.Source,
								updated: this.updated,
							};
							break;
						// 21
						case 'SolarTransit':
							comp.props = {
								name: this.idirect.Name,
								solarseason: this.idirect.stats.data.solarseason,
								solar: this.idirect.stats.data.solar,
								lat: this.idirect.stats.data.lat,
								lon: this.idirect.stats.data.lon,
							};
							break;
						// 22
						case 'SolarwindsGraphs':
							comp.props = {
								id: widget.DeviceId,
								name: this.data.Name,
								device: this.data,
								properties: widget?.properties,
								updated: this.updated,
							};
							break;
						// 23
						case 'IdirectHeatMap':
							comp.props = {
								name: this.idirect.Name,
								DID: this.idirect.Source,
							};
							break;
						// 24
						case 'SolarwindsIPSLA':
							comp.props = {
								id: widget.DeviceId,
								name: this.data.Name,
								ipsla: widget.properties?.ipsla,
								properties: widget?.properties,
								devices: this.data,
								updated: this.updated,
							};

							break;
						// 25
						case 'Solarwinds4G':
							comp.props = {
								id: widget.DeviceId,
								name: this.data.Name,
							};
							break;
						// 26
						case 'SolarwindsComtechEbN0':
							comp.props = {
								name: this.data.Name,
								prom: this.getPromise('comtech', this.data.Source),
							};
							break;
						// 27
						case 'SolarwindsComtechPower':
							comp.props = {
								name: this.data.Name,
								prom: this.getPromise('comtech', this.data.Source),
							};
							break;
						// 28
						case 'SolarwindsComtechTemp':
							comp.props = {
								name: this.data.Name,
								prom: this.getPromise('comtech', this.data.Source),
							};
							break;
						// 29
						case 'SolarwindsComtechInterfaces':
							comp.props = {
								name: this.data.Name,
								prom: this.getPromise('comtech', this.data.Source),
							};
							break;
						// 30
						case 'SolarwindsIPSLAAverages':
							comp.props = {
								id: widget.DeviceId,
								name: this.data.Name,
							};
							break;
						// 33
						case 'SolarwindsDailyGraph':
							comp.props = {
								id: widget.DeviceId,
								name: this.data.Name,
								device: this.data,
								properties: widget?.properties,
							};
							break;
						// 34
						case 'SolarTransitComtech':
							comp.props = {
								name: this.data.Name,
								properties: this.data.properties,
							};
							break;
						// 35
						case 'SolarwindsNetFlow':
							comp.props = {
								id: widget.DeviceId,
								siteid: this.data.SiteId,
								name: this.data.Name,
							};
							break;
						// 36
						case 'SiteComtechMap':
							comp.props = {
								name: this.data.Name,
								properties: this.data.properties,
							};
							break;
						// 37
						case 'SolarwindsAvailability':
							comp.props = {
								id: widget.DeviceId,
								name: this.data.Name,
							};
							break;
						// 998
						case 'SiteQuota':
							comp.props = {
								quota: this.data.quota,
							};
							break;
					}
					components[widget.WidgetArrId] = comp;
				}
			}

			return components;
		},
		getPromise(widget, source) {
			switch (widget) {
				case 'comtech':
					return this.$http.get(`swcomtech/${source}`);
				case 'modemStatus':
					var res = { data: { data: {} } };
					res.data.data[this.id] = this.idirect.status;
					return Promise.resolve(res);
			}
		},
		dateRangeChanged() {
			this.load();
		},
		async load() {
			this.loaded = false;
			let resp = await this.$http.get(`device/${this.id}`);
			this.data = resp.data.data;
			let resp2 = await this.$http.get(`widgets/device/${this.id}`);
			this.widgetslist = resp2.data.data.widgets;
			if (this.data.TypeId == 0) {
				let resp3 = await this.$http.get(`/device/idirect/${this.id}`);
				this.idirect = resp3.data.data;
			}

			this.widgets = this.setComponentProps();
			this.loaded = true;
		},
	},
	async created() {
		await this.load();
		this.$store.dispatch(SET_BREADCRUMB, [{ title: '' }, { title: 'Device - ' + this.data.Name }]);
	},
};
</script>

<style>
</style>